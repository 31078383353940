import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContactExpert from "../../../components/controls/ContactExpert";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { Link, useStaticQuery, graphql } from "gatsby";
import TitleSubDiv from "../../../components/controls/TitleSubDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Layout from "../../../components/layout/Layout";

const TexturedMelamine = () => {
  const heroImage = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "textured-melamine.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      legno: file(
        relativePath: { eq: "textured-melamine/legno-collection.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
      artika: file(relativePath: { eq: "textured-melamine/8.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);

  const path = ["Textured Melamine in Southern California"];
  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="Textured Melamine - So Cal"
          description="Textured melamine is a durable and versatile material commonly used in cabinetry, countertops, and furniture due to its scratch and heat resistance." 
        />
        <Header />
        <TitleSubDiv
          parentText="Products"
          title="Textured Melamine in Southern California"
          className="mb-8"
          path={path}
        />
        <ContentContainer
          className="w-full max-w-content md:mx-5 transform scale-x-flip flex items-center justify-center"
          opacityClassName="bg-opacity-60"
          image={heroImage.backGround}
        >
          <div className="text-white my-20 transform scale-x-flip text-center text-3xl gap-y-4 w-full max-w-xl">
            <p className="font-bold">Textured Melamine from StevensWood</p>
            <p>Changing the World of Surfaces</p>
            <p className="text-base pt-5">
              These textured melamine panels give the look of real wood color,
              texture, and grain but it’s more economical, lower maintenance plus
              scratch and water resistant sheets and boards.
            </p>
            <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
              Request free quote
            </ButtonAction>
          </div>
        </ContentContainer>

        <ContentContainer
          containerClassName="w-full mt-10 md:mt-24"
          className="flex flex-col px-0 md:px-4 w-full max-w-7xl"
        >
          <div className="flex flex-col sm:flex-row">
            <div className="w-full sm:w-7/12 p-5 sm:pl-0 sm:pr-8">
              <p className="text-3xl font-bold pb-12 text-center md:text-left">
                StevensWood Textured Melamine
              </p>
              <p>
                The newest look in cabinet design is here – textured melamine
                cabinets! Now available through National Wood Products – Southern
                California – StevensWood® textured laminates offer unlimited
                surface textures with unique designs. StevensWood® fits the look
                and feel of today’s spaces.
              </p>
              <p>
                Each collection; Legno, Artika and Rain, is Greengold Certified so
                you are getting an environmentally friendly and sustainable
                product to use in your finished products.
              </p>
              <p>
                National Wood Products is your local supplier of textured melamine
                throughout Southern California.
              </p>
            </div>
            <div className="w-full sm:w-5/12 flex flex-col items-center justify-center gap-y-4">
              <StaticImage
                src="../../../images/textured-melamine/stevenswood-textured-melamine-1.png"
                alt="Stevenswood"
                placeholder="blurred"
                quality={100}
                layout="constrained"
              />
              <div className=" w-full text-sm text-center mb-10 sm:mb-0">
                <i>
                  StevensWood Midnight Run kitchen by{" "}
                  <a
                    href="https://paragonconceptsco.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Paragon Concepts
                  </a>
                  , Longmont, CO
                </i>
              </div>
            </div>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full max-w-content mt-8 md:mt-16">
          <h2 className="text-center bg-gray1 py-8">COLLECTIONS</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-5 sm:gap-11 mt-0 md:mt-8">
            <Link to="./legno">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.legno}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-10 sm:px-16 py-3 text-lg rounded-full text-center">
                  Legno Collection
                </p>
              </ContentContainer>
            </Link>
            <Link to="./artika">
              <ContentContainer
                className="py-24 md:py-48 flex items-center"
                image={heroImage.artika}
                opacityClassName="bg-opacity-50"
              >
                <p className="bg-actionBlue text-white px-10 sm:px-16 py-3 text-lg rounded-full text-center">
                  The Artika & Rain Collection
                </p>
              </ContentContainer>
            </Link>
          </div>
        </ContentContainer>

        <ContentContainer className="w-full max-w-content mt-16 mb-6">
          <div className="bg-gray2 text-center flex flex-col gap-y-10 py-10 sm:py-24 px-5 xl:px-102 text-lg md:text-xl">
            <i>
              “If you are creating the warmth of contemporary rustic, or focusing
              on a fresh modern style, you will surely enjoy the feel and look of
              our luxuriously affordable and durable wood alternative.”
            </i>
            <p>- StevensWood®</p>
          </div>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default TexturedMelamine;
